/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  attendance: [],

  offset: 0,
  limit: 10,
  isAllFetched: false,
});

// getters
const getters = {

};

// actions
const actions = {

  async fetchAttendance({ state, commit, rootState }, { groupId, reload = false, signal } = {}) {
    const id = groupId ?? rootState.group.id;

    if (reload) {
      commit('setAttendance', []);
      commit('setOffset', 0);
      commit('setIsAllFetched', false);
    }
    if (state.isAllFetched) return state.attendance;

    const response = await $api.group._(id).attendance.get({
      offset: state.offset,
      limit: state.limit,
    }, { signal });

    commit('setAttendance', [...state.attendance, ...response.results]);
    commit('setOffset', state.offset + state.limit);
    commit('setIsAllFetched', !response.next);

    return state.attendance;
  },

  createAttendance({ dispatch, rootState }, { groupId, data, updateState = true }) {
    const id = groupId ?? rootState.group.id;
    return $api.group._(id).attendance.post(data)
      .then((response) => {
        if (updateState) {
          dispatch('fetchAttendance', { reload: true });
        }
        return response;
      });
  },

  updateAttendance({ dispatch, rootState }, {
    groupId, attendanceId, data, updateState = true,
  }) {
    const id = groupId ?? rootState.group.id;
    return $api.group._(id).attendance._(attendanceId).patch(data)
      .then((response) => {
        if (updateState) {
          dispatch('fetchAttendance', { reload: true });
        }
        return response;
      });
  },

};

// mutations
const mutations = {
  setAttendance(state, attendance) {
    state.attendance = attendance;
  },
  setOffset(state, offset) {
    state.offset = offset;
  },
  setIsAllFetched(state, value) {
    state.isAllFetched = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
