/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  courses: [],
});

// getters
const getters = {

};

// actions
const actions = {

  fetchCourses({ commit, rootState }, memberId) {
    const memId = memberId ?? rootState.member.id;
    return $api.member._(memId).teacher.courses.get()
      .then((response) => {
        commit('setCourses', response);
        return response;
      });
  },

};

// mutations
const mutations = {
  setCourses(state, courses) {
    state.courses = courses;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
