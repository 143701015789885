/* eslint prefer-template: "off" */
const view = (name) => () => import('../views/' + name + '.vue');

export default [
  // settings
  {
    path: 'settings',
    name: 'organization-settings',
    component: view('admin/organization/settings/index'),
    redirect: { name: 'organization-settings-general' },
    children: [
      {
        path: 'general',
        name: 'organization-settings-general',
        component: view('admin/organization/settings/general'),
      },
      {
        path: 'branches',
        name: 'organization-settings-branches',
        component: view('admin/organization/settings/branches'),
      },
      {
        path: 'courses',
        name: 'organization-settings-courses',
        component: view('admin/organization/settings/courses'),
      },
      {
        path: 'fields',
        name: 'organization-settings-fields',
        component: view('admin/organization/settings/customFields'),
      },
      {
        path: 'attendance',
        name: 'organization-settings-attendance',
        component: view('admin/organization/settings/attendance'),
      },
      {
        path: 'documents',
        name: 'organization-settings-documents',
        component: view('admin/organization/settings/documents'),
      },
      {
        path: 'leads',
        name: 'organization-settings-leads',
        component: view('admin/organization/settings/leads'),
      },
      {
        path: 'sms',
        name: 'organization-settings-sms',
        component: view('admin/organization/settings/sms'),
      },
      {
        path: 'notifications',
        name: 'organization-settings-notifications',
        component: view('admin/organization/settings/notifications'),
      },
    ],
  },
  // leads
  {
    path: 'leads',
    name: 'leads',
    component: view('admin/leads/index'),
    children: [
      {
        path: ':leadId(\\d+)',
        name: 'leads-lead',
        component: view('admin/leads/lead'),
        props: true,
      },
      {
        path: ':leadId(\\d+)/edit',
        name: 'leads-lead-edit',
        component: view('admin/leads/form'),
        props: true,
      },
      {
        path: 'add',
        name: 'leads-add',
        component: view('admin/leads/form'),
      },
    ],
  },
  // branch
  {
    path: 'branch/:branchId(\\d+)',
    name: 'branch',
    component: view('admin/branch/index'),
    props: true,
    redirect: { name: 'branch-overview' },
    children: [
      {
        path: 'overview/:classroomId(\\d+)?',
        name: 'branch-overview',
        component: view('admin/branch/overview'),
        props: true,
      },
      {
        path: 'group/:groupId(\\d+)',
        name: 'branch-group',
        component: view('admin/branch/group/index'),
        props: true,
      },
      {
        path: 'group/:groupId(\\d+)/edit',
        name: 'branch-group-edit',
        component: view('admin/branch/group/form'),
        props: true,
      },
      {
        path: 'group/add',
        name: 'branch-group-add',
        component: view('admin/branch/group/form'),
        props: true,
      },
    ],
  },
  // students
  {
    path: 'students',
    name: 'students',
    component: view('admin/members/index'),
    props: {
      roles: ['STD'],
    },
    children: [
      {
        path: ':memberId(\\d+)',
        name: 'students-member',
        component: view('admin/members/student'),
        props: true,
      },
      {
        path: ':memberId(\\d+)/edit',
        name: 'students-member-edit',
        component: view('admin/members/form'),
        props: true,
      },
      {
        path: 'add',
        name: 'students-add',
        component: view('admin/members/form'),
        props: {
          roles: ['STD'],
        },
      },
    ],
  },
  // teachers
  {
    path: 'teachers',
    name: 'teachers',
    component: view('admin/members/index'),
    props: {
      roles: ['THR'],
    },
    children: [
      {
        path: ':memberId(\\d+)',
        name: 'teachers-member',
        component: view('admin/members/teacher'),
        props: true,
      },
      {
        path: ':memberId(\\d+)/edit',
        name: 'teachers-member-edit',
        component: view('admin/members/form'),
        props: true,
      },
      {
        path: 'add',
        name: 'teachers-add',
        component: view('admin/members/form'),
        props: {
          roles: ['THR'],
        },
      },
    ],
  },
  // staff
  {
    path: 'staff',
    name: 'staff',
    component: view('admin/members/index'),
    props: {
      roles: ['OWR', 'ADM'],
    },
    children: [
      {
        path: ':memberId(\\d+)',
        name: 'staff-member',
        component: view('admin/members/staff'),
        props: true,
      },
      {
        path: ':memberId(\\d+)/edit',
        name: 'staff-member-edit',
        component: view('admin/members/form'),
        props: true,
      },
      {
        path: 'add',
        name: 'staff-add',
        component: view('admin/members/form'),
        props: {
          roles: ['OWR', 'ADM'],
        },
      },
    ],
  },

  // REPORTS
  // sms
  {
    path: 'reports/sms',
    name: 'reports-sms',
    component: view('admin/reports/sms'),
    props: true,
  },
  {
    path: 'reports/finance',
    name: 'reports-finance',
    component: view('admin/reports/finance'),
    props: true,
  },
  {
    path: 'reports/transactions',
    name: 'reports-transactions',
    component: view('admin/reports/transactions'),
    props: true,
  },
  {
    path: 'reports/cash',
    name: 'reports-cash',
    component: view('admin/reports/cash'),
    props: true,
  },
];
