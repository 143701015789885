/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  all: [],
});

// getters
const getters = {
  // eslint-disable-next-line eqeqeq
  getById: (state) => (id) => state.all.find((course) => course.id == id),
};

// actions
const actions = {
  fetchCourses({ commit, rootState }, organizationId) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).courses.get()
      .then((courses) => {
        commit('setCourses', courses);
        return courses;
      });
  },

  createCourse(
    { commit, state, rootState },
    {
      organizationId,
      data: { name, billing, salary },
    },
  ) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).courses.post({
      name,
      billing,
      salary,
    })
      .then((createdCourse) => {
        commit(
          'setCourses',
          [...state.all, createdCourse],
        );
        return createdCourse;
      });
  },

  updateCourse(
    { commit, state },
    {
      courseId,
      data: { name, billing, salary },
    },
  ) {
    return $api.course._(courseId).patch({
      name,
      billing,
      salary,
    })
      .then((updatedCourse) => {
        commit(
          'setCourses',
          state.all.map((course) => (course.id === courseId ? updatedCourse : course)),
        );
      });
  },

  deleteCourse(
    { commit, state },
    courseId,
  ) {
    return $api.course._(courseId).delete()
      .then(() => {
        commit(
          'setCourses',
          state.all.filter((course) => course.id !== courseId),
        );
      });
  },

  fetchTeachers(_, { courseId, params }) {
    return $api.course._(courseId).teachers.get(params)
      .then((teachers) => teachers);
  },

  addTeacher(_, { courseId, memberId }) {
    return $api.course._(courseId).teachers.post({
      member_id: memberId,
    });
  },

  removeTeacher(_, { courseId, memberId }) {
    return $api.course._(courseId).teachers._(memberId).delete();
  },
};

// mutations
const mutations = {
  setCourses(state, courses) {
    state.all = courses;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
