/* eslint-disable no-shadow */
import $api from '@/api/singleton';

import account from './account';
import admin from './admin';
import student from './student';
import teacher from './teacher';
import notifications from './notifications';

const state = () => ({
  id: null,
  role: null,

  firstName: null,
  lastName: null,
  tel: null,
  email: null,
  comment: null,
  isActive: null,
  isRegistered: null,

  fields: [],

  groups: [],
});

// getters
const getters = {
  // eslint-disable-next-line eqeqeq
  getSubAccountById: (state) => (id) => state.account.subAccounts.find((sa) => sa.id == id),
};

// actions
const actions = {

  fetchMember({ commit }, { memberId, signal, updateState = true } = {}) {
    return $api.member._(memberId).get(null, { signal })
      .then((response) => {
        if (updateState) {
          commit('setMember', response);
        }
        return response;
      });
  },

  fetchStudentGroups({ commit, state }, { memberId, signal } = {}) {
    const id = memberId ?? state.id;
    return $api.member._(id).student.groups.get(null, { signal })
      .then((response) => {
        commit('setGroups', response.results);
        return response.results;
      });
  },
  fetchTeacherGroups({ commit, state }, { memberId, signal } = {}) {
    const id = memberId ?? state.id;
    return $api.member._(id).teacher.groups.get(null, { signal })
      .then((response) => {
        commit('setGroups', response.results);
        return response.results;
      });
  },
  fetchGroups({ state, dispatch }, { signal } = {}) {
    const id = state.id;
    const role = state.role;
    if (role === 'STD') {
      return dispatch('fetchStudentGroups', { memberId: id, signal });
    }
    if (role === 'THR') {
      return dispatch('fetchTeacherGroups', { memberId: id, signal });
    }
    return null;
  },

  fetchAll({ dispatch }, { memberId, signal } = {}) {
    return Promise.all([
      dispatch('fetchMember', { memberId, signal }).then(() => dispatch('fetchGroups', { signal })),
      dispatch('account/fetchAccount', { memberId, signal }),
      dispatch('notifications/fetchSettings', { memberId, signal }),
    ]);
  },

  createMember({ rootState, commit }, {
    organizationId,
    data: {
      role, firstName, lastName, tel, email, comment, fields, isActive,
    },
    updateState = true,
  } = {}) {
    const _organizationId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(_organizationId).members.post({
      role,
      is_active: isActive,
      first_name: firstName,
      last_name: lastName,
      tel,
      email,
      comment,

      fields: fields?.map((f) => ({
        field_id: f.fieldId,
        data: f.data,
      })) ?? undefined,
    }).then((createdMember) => {
      if (updateState) {
        commit('setMember', createdMember);
      }
      return createdMember;
    });
  },

  updateMember({ commit, state }, {
    memberId = null,
    data: {
      firstName, lastName, tel, email, comment, fields, isActive,
    },
    updateState = true,
  } = {}) {
    const _memberId = memberId ?? state.id;
    return $api.member._(_memberId).patch({
      is_active: isActive,
      first_name: firstName,
      last_name: lastName,
      tel,
      email,
      comment,

      fields: fields?.map((f) => ({
        field_id: f.fieldId,
        data: f.data,
      })) ?? undefined,
    }).then((updatedMember) => {
      if (updateState) {
        commit('setMember', updatedMember);
      }
      return updatedMember;
    });
  },

  deleteMember({ state }, memberId) {
    const _memberId = memberId ?? state.id;
    return $api.member._(_memberId).delete();
  },

};

// mutations
const mutations = {
  setMember(state, member) {
    state.id = member.id;
    state.role = member.role;

    state.firstName = member.first_name;
    state.lastName = member.last_name;
    state.tel = member.tel;
    state.email = member.email;
    state.comment = member.comment;
    state.isActive = member.is_active;
    state.isRegistered = member.is_registered;

    state.fields = member.fields.map((f) => ({
      fieldId: f.field_id,
      data: f.data,
    }));
  },
  setGroups(state, groups) {
    state.groups = groups.map((g) => ({
      active: g.active,
      subAccountId: g.sub_account_id,
      group: {
        id: g.group.id,
        branchId: g.group.branch_id,
        courseId: g.group.course_id,
        name: g.group.name,
        endDate: g.group.end_date,
        billing: g.group.billing,
        schedule: g.group.schedule?.map((s) => ({
          classroomId: s.classroom_id,
          weekDay: s.week_day,
          startTime: s.start_time,
          duration: s.duration,
        })) ?? [],
      },
    }));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

  modules: {
    account,
    admin,
    student,
    teacher,
    notifications,
  },
};
