/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  all: [],
});

// getters
const getters = {
  // eslint-disable-next-line eqeqeq
  getById: (state) => (id) => state.all.find((member) => member.id == id),
};

// actions
const actions = {
  fetchStudents({ rootState, commit }, { groupId, signal } = {}) {
    const id = groupId ?? rootState.group.id;
    return $api.group._(id).students.get({ limit: 100 }, { signal })
      .then((response) => {
        commit('setStudents', response.results);
        return response.results;
      });
  },

  addStudent({ rootState, dispatch }, { groupId = null, memberId, updateState = true } = {}) {
    const id = groupId ?? rootState.group.id;
    return $api.group._(id).students.post({
      member_id: memberId,
    }).then((response) => {
      if (updateState) {
        dispatch('fetchStudents');
      }
      return response;
    });
  },
  removeStudent({ rootState, dispatch }, { groupId = null, memberId, updateState = true } = {}) {
    const id = groupId ?? rootState.group.id;
    return $api.group._(id).students._(memberId).delete()
      .then((response) => {
        if (updateState) {
          dispatch('fetchStudents');
        }
        return response;
      });
  },
};

// mutations
const mutations = {
  setStudents(state, students) {
    state.all = students;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
