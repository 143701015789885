/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  branches: [],
});

// getters
const getters = {

};

// actions
const actions = {

  fetchBranches({ commit, rootState }, memberId) {
    const memId = memberId ?? rootState.member.id;
    return $api.member._(memId).admin.branches.get()
      .then((response) => {
        commit('setBranches', response);
        return response;
      });
  },

};

// mutations
const mutations = {
  setBranches(state, branches) {
    state.branches = branches;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
