/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  data: {
    id: null,
    firstName: null,
    lastName: null,
    tel: null,
    email: null,
    isVerified: null,
  },
});

// getters
const getters = {};

// actions
const actions = {

  async fetchUser({ state, commit, dispatch }) {
    const response = await $api.user.get();
    commit('setUser', response);
    // TODO: doesnt look right
    if (state.data.isVerified) {
      await dispatch('organizations/fetchOrganizations', null, { root: true });
    }
    return response;
  },

  // eslint-disable-next-line no-empty-pattern
  registerUser({}, {
    firstName,
    lastName,
    tel,
    email,
    password,
  }) {
    return $api.user.register.post({
      first_name: firstName,
      last_name: lastName,
      tel: tel.replace(/[^0-9+]/g, ''),
      email,
      password,
    }).then((response) => {
      window.dataLayer.push({ event: 'user_registration' });
      return response;
    });
  },

  sendVerificationCode(_, sms) {
    return $api.user.verification.get({ sms });
  },

  async verifyUser({ commit, dispatch }, { code }) {
    const response = await $api.user.verification.post({
      code,
    });
    const result = response.verified;
    commit('setUserVerification', result);
    // TODO: doesnt look right
    if (result) {
      await dispatch('organizations/fetchOrganizations', null, { root: true });
    }
    return result;
  },

  sendResetCode(_, { tel, sms }) {
    return $api.user.reset.get({ tel, sms });
  },

  async resetPassword(_, { tel, code, password }) {
    const response = await $api.user.reset.post({
      tel, code, password,
    });
    const result = response.success;
    return result;
  },

  updateUser({ commit }, {
    data: {
      firstName, lastName, email,
    },
    updateState = true,
  } = {}) {
    return $api.user.patch({
      first_name: firstName,
      last_name: lastName,
      email,
    }).then((response) => {
      if (updateState) {
        commit('setUser', response);
      }
      return response;
    });
  },

  changePassword(_, { oldPassword, newPassword }) {
    return $api.user.password.patch({
      old_password: oldPassword,
      new_password: newPassword,
    });
  },

};

// mutations
const mutations = {
  setUser(state, user) {
    ({
      id: state.data.id,
      first_name: state.data.firstName,
      last_name: state.data.lastName,
      tel: state.data.tel,
      email: state.data.email,
      is_verified: state.data.isVerified,
    } = user);
  },
  setUserVerification(state, isVerified) {
    state.data.isVerified = isVerified;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
