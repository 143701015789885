<template>
  <div class="fixed z-40 top-2 right-1/2 max-w-[96vw] flex flex-col-reverse"> <!-- md:right-4 md:translate-x-0 -->
    <ui-notification
      v-for="notif in queue"
      :key="notif.time"

      position="static"
      positionX="center"
      :type="notif.type"
      :message="notif.message"
      :detail="notif.detail"
      :color="notif.color"
      :timeout="notif.timeout ?? timeout"

      v-model="notif.show"
      @update:model-value="onClose(notif.time)"
    />
  </div>
</template>

<script>
import uiNotification from '@/components/ui/uiNotification.vue';

export default {
  components: { uiNotification },
  props: {
    length: {
      type: Number,
      default: 3,
    },
    timeout: {
      type: Number,
      default: 4000,
    },
  },
  data() {
    return {
      queue: [],
    };
  },
  computed: {
    notifications() {
      return this.$store.state.ui.notifications;
    },
  },
  methods: {
    showNext() {
      if (
        this.notifications.length === 0
        || this.queue.length >= this.length
      ) {
        return;
      }
      this.queue.push({
        ...this.notifications[0],
        show: true,
      });
      this.shiftNotifications();
    },
    onClose(time) {
      // give animations a little time to leave
      setTimeout(() => {
        this.queue = this.queue.filter((notif) => notif.time !== time);
        this.showNext();
      }, 200);
    },
    shiftNotifications() {
      this.$store.commit('ui/shiftNotifications');
    },
  },
  watch: {
    notifications() {
      this.showNext();
    },
  },
};
</script>
