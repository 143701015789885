/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  finance: [],
});

// getters
const getters = {};

// actions
const actions = {

  fetchOrganizationFinancialReport({ commit, rootState }, { organizationId = null, period = '1y' } = {}) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).reports.finance.get({ period })
      .then((response) => {
        commit('setFinancialReport', response);
        return response;
      });
  },

  fetchBranchFinancialReport({ commit }, { branchId, period = '1y' } = {}) {
    return $api.branch._(branchId).reports.finance.get({ period })
      .then((response) => {
        commit('setFinancialReport', response);
        return response;
      });
  },

};

// mutations
const mutations = {
  setFinancialReport(state, report) {
    state.finance = report.map((r) => ({
      year: r.year,
      month: r.month,
      incomes: r.incomes,
      expenses: r.expenses,
      profits: r.profits,
      estIncomes: r.est_incomes,
      estExpenses: r.est_expenses,
      estProfits: r.est_profits,
      updatedAt: r.updated_at,
    }));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
