import './styles.scss';

import { createApp } from 'vue';
import { AxiosError } from 'axios';

import ApiClient from './api/singleton';
import App from './App.vue';
import router from './router';
import store from './store';

import dirSidebar from './directives/sidebar';
import dirSticky from './directives/sticky';

// Tune API
ApiClient.interceptResponse(
  null,
  ((error: AxiosError) => {
    if (error.response) {
      // token expired / unauthenticated
      if (error.response.status === 401) {
        if (store.state.auth.isAuthenticated) {
          store.commit('ui/addNotification', {
            message: 'Время сессии истекло',
            detail: 'Войдите заново',
            type: 'error',
          });
        }
        store.dispatch('auth/logOut');
        router.push({ name: 'logIn' });
      } else if (error.response.status === 404) { // not found
        router.replace({ name: '404' });
      } else if (error.response.status === 403) { // access denied
        router.push({ name: 'index' });
        store.commit('ui/addNotification', {
          message: 'Доступ запрещен',
          type: 'error',
        });
      } else if (error.response.status === 429) { // too many requests
        store.commit('ui/addNotification', {
          message: 'Слишком много запросов',
          detail: 'Подождите и перзагрузите страницу',
          type: 'error',
        });
      } else if (error.response.status === 500) { // some uncatched error
        store.commit('ui/addNotification', {
          message: 'Произошла неизвестная ошибка',
          detail: 'Если ошибка будет повторяться, обратитесь в техническую поддержку',
          type: 'error',
          color: 'error',
        });
      }
    }
    return Promise.reject(error);
  }) as any, // !!!
);

const app = createApp(App)
  .use(store)
  .use(router);

app.config.globalProperties.$api = ApiClient;
app.provide('api', ApiClient);

app.directive('sidebar', dirSidebar);
app.directive('sticky', dirSticky);

app.mount('#app');
