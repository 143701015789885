const EndpointProxy = {
  get(target, prop, receiver) {
    if (prop in target) {
      return Reflect.get(target, prop, receiver);
    }
    // some nasty Vue bug
    if (typeof prop !== 'string' || prop.startsWith('__v_')) {
      return undefined;
    }
    return receiver._(prop);
  },
};

class Endpoint {
  constructor(client, urlComponents = []) {
    this.client = client;
    this.urlComponents = urlComponents;
    // eslint-disable-next-line no-constructor-return
    return new Proxy(this, EndpointProxy);
  }

  get url() {
    return this.urlComponents.join('/');
  }

  _(component) {
    return new Endpoint(this.client, [...this.urlComponents, component]);
  }

  get(params, config) {
    return this.client.get(this.url, params, config);
  }

  post(data, params = null) {
    return this.client.post(this.url, data, params);
  }

  patch(data, params = null) {
    return this.client.patch(this.url, data, params);
  }

  delete(params) {
    return this.client.delete(this.url, params);
  }
}

export default Endpoint;
