/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  all: [],
});

// getters
const getters = {
  // eslint-disable-next-line eqeqeq
  getById: (state) => (id) => state.all.find((doc) => doc.id == id),
};

// actions
const actions = {
  create({ commit, state, rootState }, data) {
    return $api.organization._(rootState.organizations.currentId).documents.post(data)
      .then((createdDoc) => {
        commit(
          'setDocuments',
          [...state.all, createdDoc],
        );
        return createdDoc;
      });
  },
  update({ commit, state }, { id, data }) {
    return $api.document._(id).patch(data)
      .then((updatedDoc) => {
        commit(
          'setDocuments',
          state.all.map((doc) => (doc.id === id ? updatedDoc : doc)),
        );
      });
  },
  delete({ commit, state }, id) {
    return $api.document._(id).delete()
      .then(() => {
        commit(
          'setDocuments',
          state.all.filter((doc) => doc.id !== id),
        );
      });
  },
  load({ commit, rootState }) {
    const organizationId = rootState.organizations.currentId;
    return $api.organization._(organizationId).documents.get()
      .then((response) => {
        commit('setDocuments', response.results);
        return response.results;
      });
  },
};

// mutations
const mutations = {
  setDocuments(state, docs) {
    state.all = docs;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
