/* eslint-disable no-shadow */
import $api from '@/api/singleton';

import history from './history';

const state = () => ({
  id: null,
  statusId: null,
  createdAt: null,
  creatorId: null,
  active: null,
  source: null,

  firstName: null,
  lastName: null,
  tel: null,
  email: null,
  comment: null,

  fields: [],
  // history: [],
});

// getters
const getters = {};

// actions
const actions = {

  fetchLead({ commit }, leadId) {
    return $api.lead._(leadId).get()
      .then((response) => {
        commit('setLead', response);
        return response;
      });
  },

  createLead({ commit, rootState }, {
    organizationId,
    data: {
      firstName, lastName, tel, email, comment, fields,
    },
    updateState = true,
  } = {}) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).leads.post({
      first_name: firstName,
      last_name: lastName,
      tel,
      email,
      comment,
      fields: fields
        ?.filter(/* filter out empty custom fields */(f) => f.data)
        .map((f) => ({
          field_id: f.fieldId,
          data: f.data,
        }))
        ?? undefined,
    }).then((response) => {
      if (updateState) {
        commit('setLead', response);
      }
      return response;
    });
  },

  updateLead({ state, commit }, {
    leadId = null,
    data: {
      active, firstName, lastName, tel, email, comment, fields,
    },
    updateState = true,
  } = {}) {
    const id = leadId ?? state.id;
    return $api.lead._(id).patch({
      active,
      first_name: firstName,
      last_name: lastName,
      tel,
      email,
      comment,
      fields: fields
        ?.filter(/* filter out empty custom fields */(f) => f.data)
        .map((f) => ({
          field_id: f.fieldId,
          data: f.data,
        }))
        ?? undefined,
    }).then((response) => {
      if (updateState) {
        commit('setLead', response);
      }
      return response;
    });
  },

  deleteLead({ state }, leadId) {
    const id = leadId ?? state.id;
    return $api.lead._(id).delete();
  },

};

// mutations
const mutations = {
  setLead(state, lead) {
    state.id = lead.id;
    state.statusId = lead.status_id;
    state.createdAt = lead.created_at;
    state.creatorId = lead.creator_id;
    state.active = lead.active;
    state.source = lead.source;

    state.firstName = lead.first_name;
    state.lastName = lead.last_name;
    state.tel = lead.tel;
    state.email = lead.email;
    state.comment = lead.comment;

    state.fields = lead.fields.map((f) => ({
      fieldId: f.field_id,
      data: f.data,
    }));
    // WARNING: undocumented calling
    // this.commit('lead/setLeadStatusHistory', lead.history);
  },
  /* setLeadStatusHistory(state, history) {
    state.history = history.map((h) => ({
      id: h.id,
      createdAt: h.created_at,
      statusId: h.status_id,
      creatorId: h.creator_id,
      comment: h.comment,
    }));
  }, */
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

  modules: {
    history,
  },
};
