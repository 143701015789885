/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({});

// getters
const getters = {};

// actions
const actions = {
  fetchClassrooms(_, { branchId, params }) {
    return $api.branch._(branchId).classrooms.get(params)
      .then((classrooms) => classrooms);
  },

  createClassroom(
    _,
    {
      branchId,
      data: {
        name,
      },
    },
  ) {
    return $api.branch._(branchId).classrooms.post({
      name,
    })
      .then((createdClassroom) => createdClassroom);
  },

  updateClassroom(
    _,
    {
      branchId,
      classroomId,
      data: {
        name,
      },
    },
  ) {
    return $api.branch._(branchId).classrooms._(classroomId).patch({
      name,
    })
      .then((updatedClassroom) => updatedClassroom);
  },

  deleteClassroom(_, {
    branchId,
    classroomId,
  }) {
    return $api.branch._(branchId).classrooms._(classroomId).delete();
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
