<template>
  <transition
    appear
    :enter-from-class="animationClasses"
    enter-to-class="opacity-100 translate-x-0"
    enter-active-class="ease-out duration-200"
    leave-from-class="opacity-100 translate-x-0"
    :leave-to-class="animationClasses"
    leave-active-class="ease-in duration-200"
  >
    <div
      v-if="modelValue"
      class="z-40 w-96 max-w-full transform-gpu mb-2"
      :class="{
        fixed: position == 'fixed',
        absolute: position == 'absolute',
        'top-4': positionY == 'top',
        'bottom-4': positionY == 'bottom',
        'right-4': positionX == 'left',
        'left-4': positionX == 'right',
        'left-1/2 translate-x-1/2': positionX == 'center',
      }"
    >
      <div
        class="rounded-md ring-1 ring-black ring-opacity-10 px-4 py-3 shadow-2xl text-sm opacity-90 hover:opacity-100 transition-opacity"
        :class="{
          'bg-gray-600': !color,
          'bg-success-400': color == 'success',
          'bg-info-400': color == 'info',
          'bg-warning-400': color == 'warning',
          'bg-error-400': color == 'error',
        }"
      >
        <div class="flex items-start">

          <div
            v-if="type"
            class="mx-auto flex-shrink-0 pr-4"
            :class="{
              'text-white': !!color,
              'text-success-500': type == 'success',
              'text-info-500': type == 'info',
              'text-warning-500': type == 'warning',
              'text-error-600': type == 'error',
            }"
          >
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path v-if="type == 'success'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path v-else-if="type == 'info'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path v-else-if="type == 'warning'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              <path v-else-if="type == 'error'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>

          <div class="flex-grow">
            <div
              class="text-white text-opacity-90"
              :class="{
                'font-medium': detail,
              }"
            >{{message}}</div>
            <div v-if="detail" class="mt-1 text-white text-opacity-80">{{detail}}</div>
          </div>

          <div v-if="dismissible" @click="$emit('update:modelValue', false)" class="ml-4 flex-shrink-0 text-white text-opacity-80 hover:text-opacity-100 cursor-pointer">
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {

  props: {
    modelValue: Boolean,

    type: {
      type: String,
      validator(value) {
        return ['success', 'info', 'warning', 'error'].indexOf(value) !== -1;
      },
    },
    message: String,
    detail: String,

    position: {
      type: String,
      validator(value) {
        return ['fixed', 'absolute', 'static'].indexOf(value) !== -1;
      },
      default: 'fixed',
    },
    positionX: {
      type: String,
      validator(value) {
        return ['left', 'center', 'right'].indexOf(value) !== -1;
      },
      default: 'right',
    },
    positionY: {
      type: String,
      validator(value) {
        return ['top', 'bottom'].indexOf(value) !== -1;
      },
      default: 'top',
    },

    color: String,
    dismissible: {
      type: Boolean,
      default: true,
    },
    timeout: {
      type: Number,
      default: 3000,
    },
  },
  data() {
    return {
      timer: null,
    };
  },

  methods: {
    setTimer() {
      clearTimeout(this.timer);
      if (this.modelValue && this.timeout) {
        this.timer = setTimeout(() => {
          this.$emit('update:modelValue', false);
        }, this.timeout);
      }
    },
  },
  created() {
    this.setTimer();
  },
  watch: {
    modelValue() {
      this.setTimer();
    },
  },
  computed: {
    animationClasses() {
      const classes = ['opacity-0'];
      if (this.positionX === 'right') {
        classes.push('translate-x-80');
      } else if (this.positionX === 'left') {
        classes.push('-translate-x-80');
      } else if (this.positionX === 'center' && this.positionY === 'top') {
        classes.push('-translate-y-20');
      } else if (this.positionX === 'center' && this.positionY === 'bottom') {
        classes.push('translate-y-20');
      }
      return classes.join(' ');
    },
  },
};
</script>
