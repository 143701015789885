import { createStore, createLogger } from 'vuex';

import ui from './modules/ui';
import auth from './modules/authentication';
import user from './modules/user';
import organizations from './modules/organizations';
import settings from './modules/settings/index';
import sms from './modules/sms';
import documents from './modules/documents';
import branches from './modules/branches/index';
import courses from './modules/courses';
import member from './modules/member/index';
import group from './modules/group/index';
import lead from './modules/lead/index';
import leads from './modules/leads';
import reports from './modules/reports';

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
  modules: {
    ui,
    auth,
    user,
    organizations,
    settings,
    sms,
    documents,
    branches,
    courses,
    member,
    group,
    lead,
    leads,
    reports,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

export default store;
