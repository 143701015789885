/* eslint-disable no-shadow */

const state = () => ({
  title: 'Everyclass',
  isLoading: false,
  isLoadingView: 0,
  hasMenu: false,
  isMenuOpen: true,
  notifications: [],
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setTitle(state, value) {
    state.title = value ? `${value} — Everyclass` : 'Everyclass';
  },

  setLoading(state, value) {
    state.isLoading = value;
  },

  setLoadingView(state, value) {
    state.isLoadingView = value;
  },

  setMenuStatus(state, value) {
    state.hasMenu = value;
  },

  setMenuState(state, value) {
    state.isMenuOpen = value;
  },

  addNotification(state, {
    message, detail = null, type = null, color = null, timeout = null,
  } = {}) {
    const notif = {
      message,
      detail,
      type,
      color,
      timeout,
      time: Date.now(),
    };
    state.notifications = [...state.notifications, notif];
  },
  shiftNotifications(state) {
    state.notifications = state.notifications.slice(1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
