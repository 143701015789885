/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  customFields: [],
});

// getters
const getters = {
  getRoleCustomFields: (state) => (role) => state.customFields.filter((field) => field.role === role),
};

// actions
const actions = {
  reorderCustomFields({ dispatch, getters }, { role, fromIndex, toIndex }) {
    const movedUp = toIndex < fromIndex;
    const movedDown = !movedUp;
    const changedFields = [];
    getters.getRoleCustomFields(role).forEach((field, index) => {
      if (index === fromIndex) {
        // change
        changedFields.push({
          id: field.id,
          data: {
            order: toIndex,
          },
        });
      } else if (movedUp && index >= toIndex) {
        // inc
        changedFields.push({
          id: field.id,
          data: {
            order: index + 1,
          },
        });
      } else if (movedDown && index <= toIndex) {
        /// dec
        changedFields.push({
          id: field.id,
          data: {
            order: index - 1,
          },
        });
      }
    });
    return Promise.all(
      changedFields.map((field) => dispatch('updateCustomField', field)),
    );
  },
  createCustomField({ commit, state, rootState }, data) {
    return $api.organization._(rootState.organizations.currentId).members.fields.post(data)
      .then((createdField) => {
        commit(
          'setCustomFields',
          [...state.customFields, createdField],
        );
        return createdField;
      });
  },
  updateCustomField({ commit, state, rootState }, { id, data }) {
    return $api.organization._(rootState.organizations.currentId).members.fields._(id).patch(data)
      .then((updatedField) => {
        commit(
          'setCustomFields',
          state.customFields.map((f) => (f.id === id ? updatedField : f)),
        );
        return updatedField;
      });
  },
  deleteCustomField({ commit, state, rootState }, id) {
    return $api.organization._(rootState.organizations.currentId).members.fields._(id).delete()
      .then(() => {
        commit(
          'setCustomFields',
          state.customFields.filter((f) => f.id !== id),
        );
      });
  },
  fetchCustomFields({ commit, rootState }) {
    return $api.organization._(rootState.organizations.currentId).members.fields.get()
      .then((response) => {
        commit('setCustomFields', response.results);
      });
  },
};

// mutations
const mutations = {
  setCustomFields(state, customFields) {
    state.customFields = customFields.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
