/* eslint-disable no-shadow */
// import $api from '@/api/singleton';

import attendance from './attendance';

const state = () => ({});

// getters
const getters = {
};

// actions
const actions = {

  addToGroup({ dispatch, rootState }, { memberId = null, groupId } = {}) {
    const id = memberId ?? rootState.member.id;
    return dispatch('group/students/addStudent', { groupId, memberId: id, updateState: false }, { root: true })
      .then(() => Promise.all([
        dispatch('member/account/fetchAccount', id, { root: true }),
        dispatch('member/fetchGroups', id, { root: true }),
      ]));
  },
  removeFromGroup({ dispatch, rootState }, { memberId = null, groupId } = {}) {
    const id = memberId ?? rootState.member.id;
    return dispatch('group/students/removeStudent', { groupId, memberId: id, updateState: false }, { root: true })
      .then(() => Promise.all([
        // dispatch('member/account/fetchAccount', id, { root: true }), // not required
        dispatch('member/fetchGroups', id, { root: true }),
      ]));
  },

  async transferFundsBetweenGroups({ dispatch, rootState }, {
    memberId = null, fromGroupId, toGroupId, amount, updateState = true,
  }) {
    const id = memberId ?? rootState.member.id;

    const fromGroup = rootState.member.groups.find((g) => g.group.id === fromGroupId);
    const fromSubAccount = rootState.member.account.account.subAccounts.find((sa) => sa.id === fromGroup.subAccountId);

    const toGroup = rootState.member.groups.find((g) => g.group.id === toGroupId);
    const toSubAccount = rootState.member.account.account.subAccounts.find((sa) => sa.id === toGroup.subAccountId);

    return {
      outTransaction: await dispatch('member/account/createTransaction', {
        memberId: id,
        subAccountId: fromSubAccount.id,
        amount: amount * -1,
        description: `Перевод средств на баланс группы "${toGroup.group.name}" [#${toGroup.group.id}]`,
        updateState: false,
        isReal: false,
      }, { root: true }),
      inTransaction: await dispatch('member/account/createTransaction', {
        memberId: id,
        subAccountId: toSubAccount.id,
        amount,
        description: `Перевод средств с баланса группы "${fromGroup.group.name}" [#${fromGroup.group.id}]`,
        updateState,
        isReal: false,
      }, { root: true }),
    };
  },

  async transferToGroup({ dispatch, rootState }, { memberId = null, fromGroupId, toGroupId }) {
    const id = memberId ?? rootState.member.id;

    const fromGroup = rootState.member.groups.find((g) => g.group.id === fromGroupId);
    const fromSubAccount = rootState.member.account.account.subAccounts.find((sa) => sa.id === fromGroup.subAccountId);

    const result = await dispatch('addToGroup', { memberId: id, groupId: toGroupId });

    if (fromSubAccount.balance !== 0) {
      await dispatch('transferFundsBetweenGroups', {
        memberId: id,
        fromGroupId,
        toGroupId,
        amount: fromSubAccount.balance,
        updateState: true,
      });
    }

    await dispatch('removeFromGroup', { memberId: id, groupId: fromGroupId });

    return result;
  },

};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

  modules: {
    attendance,
  },
};
