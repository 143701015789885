/* eslint-disable no-shadow */
import $api from '@/api/singleton';

import admins from './admins';
import classrooms from './classrooms';

const state = () => ({
  all: [],
});

// getters
const getters = {
  // eslint-disable-next-line eqeqeq
  getById: (state) => (id) => state.all.find((branch) => branch.id == id),
  getManagedByMe: (state, getters, rootState, rootGetters) => {
    const isOwner = rootGetters['organizations/isOwner'];
    const memberId = rootGetters['organizations/current']?.memberId;
    if (isOwner) {
      return state.all;
    }
    return state.all.filter((branch) => branch.admins.includes(memberId));
  },
};

// actions
const actions = {
  fetchBranches({ commit, rootState }, organizationId) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).branches.get()
      .then((branches) => {
        commit('setBranches', branches);
        return branches;
      });
  },

  createBranch(
    { commit, state, rootState },
    {
      organizationId,
      data: { name, timezone },
    },
  ) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).branches.post({
      name,
      timezone,
    })
      .then((createdBranch) => {
        commit(
          'setBranches',
          [...state.all, createdBranch],
        );
        return createdBranch;
      });
  },

  updateBranch(
    { commit, state },
    {
      branchId,
      data: { name, timezone },
    },
  ) {
    return $api.branch._(branchId).patch({
      name,
      timezone,
    })
      .then((updatedBranch) => {
        commit(
          'setBranches',
          state.all.map((branch) => (branch.id === branchId ? updatedBranch : branch)),
        );
        return updatedBranch;
      });
  },

  deleteBranch(
    { commit, state },
    branchId,
  ) {
    return $api.branch._(branchId).delete()
      .then(() => {
        commit(
          'setBranches',
          state.all.filter((branch) => branch.id !== branchId),
        );
      });
  },

  fetchGroups(_, { branchId, params }) {
    return $api.branch._(branchId).groups.get(params)
      .then((groups) => groups);
  },
};

// mutations
const mutations = {
  setBranches(state, branches) {
    state.all = branches;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

  modules: {
    admins,
    classrooms,
  },
};
