/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  all: [],
  currentId: null,
  isSelecting: false,
});

// getters
const getters = {
  current(state) {
    // eslint-disable-next-line eqeqeq
    return state.all.find((org) => org.id == state.currentId);
  },
  isOwner(state, getters) {
    return getters.current?.memberRole === 'OWR';
  },
  isAdmin(state, getters) {
    return getters.current?.memberRole === 'ADM';
  },
  isTeacher(state, getters) {
    return getters.current?.memberRole === 'THR';
  },
  isStudent(state, getters) {
    return getters.current?.memberRole === 'STD';
  },
};

// actions
const actions = {
  select({ commit, dispatch, getters }, organizationId) {
    commit('setSelecting', true);
    commit('setCurrentId', organizationId);
    const loaders = [
      dispatch('branches/fetchBranches', null, { root: true }),
      dispatch('courses/fetchCourses', null, { root: true }),
    ];
    if (getters.isOwner || getters.isAdmin) {
      loaders.push(
        dispatch('settings/fetchAll', null, { root: true }),
        dispatch('documents/load', null, { root: true }),
        dispatch('sms/fetchTemplates', null, { root: true }),
      );
    } else {
      loaders.push(
        dispatch('settings/attendance/fetchAttendanceStatuses', {}, { root: true }), // we need the statuses for students to see
      );
    }
    return Promise.all(loaders)
      .then((args) => {
        commit('setSelecting', false);
        commit('ui/setTitle', getters.current.name, { root: true });
        return args;
      });
  },

  createOrganization({ commit, state }, { name }) {
    return $api.organization.post({
      name,
    })
      .then((createdOrg) => {
        commit(
          'setOrganizations',
          [...state.all, createdOrg],
        );
        return createdOrg;
      });
  },

  updateOrganization({ commit, state }, { organizationId, data: { name } }) {
    const id = organizationId ?? state.currentId;
    return $api.organization._(id).patch({
      name,
    })
      .then((updatedOrg) => {
        commit(
          'setOrganizations',
          state.all.map((org) => (org.id === id ? updatedOrg : org)),
        );
        return updatedOrg;
      });
  },

  fetchOrganizations({ commit, state }) {
    return $api.organization.get()
      .then((organizations) => {
        commit('setOrganizations', organizations);
        if (organizations.length) {
          // if currently selected organization is not available anymore,
          // drop the id
          if (!organizations.find((org) => org.id === state.currentId)) {
            commit('setCurrentId', null);
          }
        } else {
          commit('setCurrentId', null);
        }
        return organizations;
      });
  },

};

// mutations
const mutations = {
  setOrganizations(state, organizations) {
    state.all = organizations.map(
      (org) => ({
        id: org.id,
        name: org.name,
        memberId: org.member_id,
        memberRole: org.member_role,
      }),
    );
  },
  setCurrentId(state, id) {
    state.currentId = id;
  },
  setSelecting(state, value) {
    state.isSelecting = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
