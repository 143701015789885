/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  attendanceStatuses: [],
});

// getters
const getters = {};

// actions
const actions = {

  fetchAttendanceStatuses({ commit, rootState }, { organizationId, params } = {}) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.attendance.get(params)
      .then((statuses) => {
        commit('setAttendanceStatuses', statuses);
      });
  },

  createAttendanceStatus(
    { commit, state, rootState },
    {
      organizationId,
      data: {
        name, presence, chargeable, color,
      },
    },
  ) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.attendance.post({
      name, presence, chargeable, color,
    })
      .then((createdStatus) => {
        commit(
          'setAttendanceStatuses',
          [...state.attendanceStatuses, createdStatus],
        );
        return createdStatus;
      });
  },

  updateAttendanceStatus(
    { commit, state, rootState },
    {
      organizationId,
      statusId,
      data: {
        name, presence, chargeable, color,
      },
    },
  ) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.attendance._(statusId).patch({
      name, presence, chargeable, color,
    })
      .then((updatedStatus) => {
        commit(
          'setAttendanceStatuses',
          state.attendanceStatuses.map((f) => (f.id === statusId ? updatedStatus : f)),
        );
        return updatedStatus;
      });
  },

  deleteAttendanceStatus({ commit, state, rootState }, {
    organizationId,
    statusId,
  }) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.attendance._(statusId).delete()
      .then(() => {
        commit(
          'setAttendanceStatuses',
          state.attendanceStatuses.filter((f) => f.id !== statusId),
        );
      });
  },

};

// mutations
const mutations = {
  setAttendanceStatuses(state, attendanceStatuses) {
    state.attendanceStatuses = attendanceStatuses;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
