import $api from '@/api/singleton';

const state = () => ({});

// getters
const getters = {};

// actions
const actions = {

  /* fetchStatusHistory({ commit, rootState }, leadId) {
    const _leadId = leadId ?? rootState.lead.id;
    return $api.lead._(_leadId).history.get()
      .then((response) => {
        commit('lead/setLeadStatusHistory', response.results, { root: true });
        return response.results;
      });
  }, */

  createStatusHistory({ rootState, dispatch }, {
    leadId,
    data: {
      statusId, comment,
    },
    updateState = true,
  } = {}) {
    const _leadId = leadId ?? rootState.lead.id;
    return $api.lead._(_leadId).history.post({
      status_id: statusId,
      comment,
    }).then((createdStatus) => {
      if (updateState) {
        // refetch the lead because adding new status updates
        // the status and active state of the lead
        dispatch('lead/fetchLead', _leadId, { root: true });
        // dispatch('fetchStatusHistory');
      }
      dispatch('leads/fetchLeadStats', {}, { root: true });
      return createdStatus;
    });
  },

  deleteStatusHistory({ rootState }, { leadId, historyId }) {
    const _leadId = leadId ?? rootState.lead.id;
    return $api.lead._(_leadId).history._(historyId).delete();
  },

};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
