import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import store from '../store';

import adminRoutes from './admin';
import studentRoutes from './student';

/* eslint prefer-template: "off" */
const view = (name) => async () => {
  let lv = store.state.ui.isLoadingView;
  store.commit('ui/setLoadingView', lv + 1);
  const v = await import('../views/' + name + '.vue');
  lv = store.state.ui.isLoadingView;
  store.commit('ui/setLoadingView', lv - 1);
  return v;
};

/* const prefixRoutes = (prefix, routes) => routes.map((r) => ({
  ...r,
  path: prefix + '/' + r.path,
})); */

const routes = [
  {
    path: '/',
    name: 'index',
    component: view('index'),

    children: [
      {
        path: 'user/settings',
        name: 'user-settings',
        component: view('user/settings/index'),
        redirect: { name: 'user-settings-personal' },
        children: [
          {
            path: 'personal',
            name: 'user-settings-personal',
            component: view('user/settings/personal'),
          },
          {
            path: 'password',
            name: 'user-settings-password',
            component: view('user/settings/password'),
          },
        ],
      },

      {
        path: 'organization/unrelated',
        name: 'organization-unrelated',
        component: view('organization/unrelated'),
      },

      {
        path: 'organization/create',
        name: 'organization-create',
        component: view('organization/create'),
      },

      {
        path: 'organization/:organizationId(\\d+)',
        name: 'organization-index',
        component: view('organization/index'),
        props: true,

        children: [
          // admin routes
          {
            path: 'admin',
            name: 'admin-index',
            component: view('admin/index'),
            children: adminRoutes,
          },
          // student routes
          {
            path: 'student/:memberId(\\d+)?',
            name: 'student-index',
            component: view('student/index'),
            children: studentRoutes,
            props: true,
          },
        ],
      },

    ],
  },

  {
    path: '/login',
    name: 'logIn',
    component: view('login'),
    meta: { public: true },
    beforeEnter: () => {
      if (store.state.auth.isAuthenticated) {
        return {
          name: 'index',
        };
      }
      return true;
    },
  },
  {
    path: '/register',
    name: 'register',
    component: view('user/register'),
    meta: { public: true },
    beforeEnter: () => {
      if (store.state.auth.isAuthenticated) {
        return {
          name: 'index',
        };
      }
      return true;
    },
  },
  {
    path: '/user/reset',
    name: 'user-reset',
    component: view('user/reset'),
    meta: { public: true },
    beforeEnter: () => {
      if (store.state.auth.isAuthenticated) {
        return {
          name: 'index',
        };
      }
      return true;
    },
  },
  {
    path: '/user/verification',
    name: 'user-verification',
    component: view('user/verification'),
    beforeEnter: () => {
      if (store.state.user.data.isVerified) {
        return {
          name: 'index',
        };
      }
      return true;
    },
  },

  {
    path: '/loading',
    name: 'loading',
    component: view('loading'),
    meta: { public: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: view('404'),
    meta: { public: true },
  },
];

const router = createRouter({
  history: process.env.NODE_ENV === 'production'
    ? createWebHistory()
    : createWebHashHistory(),
  routes,
});

router.beforeEach(async (to) => {
  // try to log in with saved credentials
  if (!store.state.auth.isAuthenticated) {
    store.commit('ui/setLoading', true);
    await store.dispatch('auth/logInWithSavedCredentials');
    store.commit('ui/setLoading', false);
  }

  // send logged out user to the log in page when try to access restricted area
  if (!to.meta.public && !store.state.auth.isAuthenticated) {
    return {
      name: 'logIn',
      replace: true,
    };
  }

  // send unverified users to the verification page
  if (to.name !== 'user-verification' && store.state.auth.isAuthenticated && !store.state.user.data.isVerified) {
    return {
      name: 'user-verification',
    };
  }

  if (store.state.auth.isAuthenticated && store.state.user.data.isVerified) {
    // fetch organizations or redirect to no-org page
    if (store.state.organizations.all.length === 0) {
      /* store.commit('ui/setLoading', true);
      const orgs = await store.dispatch('organizations/fetchOrganizations');
      store.commit('ui/setLoading', false);
      if (orgs.length === 0) {
        if (to.name !== 'organization-unrelated') {
          return {
            name: 'organization-unrelated',
          };
        }
        return true;
      } */
      if (to.name !== 'organization-unrelated') {
        return {
          name: 'organization-unrelated',
        };
      }
      return true;
    }

    const routeOrgId = to.params?.organizationId && parseInt(to.params.organizationId, 10);
    const currentOrgId = store.state.organizations.currentId;
    // if no organization is selected - select the first one
    if (!routeOrgId && !currentOrgId) {
      const orgs = store.state.organizations.all;
      await store.dispatch('organizations/select', orgs[0].id);
    } else
    // change selected organizations
    if (routeOrgId && routeOrgId !== currentOrgId) {
      await store.dispatch('organizations/select', routeOrgId);
    }
  }

  return true;
});

export default router;
