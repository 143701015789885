/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  stats: [],
});

// getters
const getters = {
  getNewLeadCount: (state) => state.stats.find((s) => s.status_id == null)?.count ?? 0,
};

// actions
const actions = {

  fetchLeadStats({ commit, rootState }, { organizationId = null } = {}) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).leads.stats.get()
      .then((response) => {
        commit('setStats', response);
        return response;
      });
  },

};

// mutations
const mutations = {
  setStats(state, stats) {
    state.stats = stats;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
