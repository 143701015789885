import { DirectiveBinding } from 'vue';

export default {
  // called when bound element's parent component is mounted
  beforeMount(el: HTMLBaseElement, { arg, modifiers, value }: DirectiveBinding) {
    el.classList.add('sidebar-container');
    if (arg === 'left') {
      el.classList.add('sidebar-container--left');
    } else {
      el.classList.add('sidebar-container--right');
    }
    if ('lg' in modifiers) {
      el.classList.add('sidebar-container--lg');
    }
    if ('collapsible' in modifiers) {
      el.classList.add('sidebar-container--collapsible');
    }
    if (value) {
      el.classList.add('sidebar-container--open');
    }
  },
  // called after the containing component's VNode and the VNodes of its children
  // have updated
  updated(el: HTMLBaseElement, { value }: {value:boolean}) {
    if (value) {
      el.classList.add('sidebar-container--open');
    } else {
      el.classList.remove('sidebar-container--open');
    }
  },
};
