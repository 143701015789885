<template>
  <slot v-if="!modelValue" />

  <transition
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    enter-active-class="ease-out duration-75"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    leave-active-class="ease-in duration-75"
  >
    <div
      v-if="modelValue"
      class="bg-white bg-opacity-30 z-30 transition-opacity pointer-events-none"
      :class="{
        'absolute inset-0': absolute,
      }"
      style="backdrop-filter: blur(7px);"
    >
      <div
        class="h-16 w-16"
        :class="{
          'absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2': absolute,
          'my-4 mx-auto': !absolute,
        }"
        style="max-height: 50%;"
      >
        <svg class="animate-spin w-full h-full text-primary-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    absolute: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>

</style>
