/* eslint-disable no-shadow */
import moment from 'moment-timezone';
import $api from '@/api/singleton';

import attendance from './attendance';
import students from './students';

const state = () => ({
  id: null,
  branchId: null,
  courseId: null,
  name: null,
  comment: null,
  startDate: null,
  endDate: null,
  teacher: {
    id: null,
    firstName: null,
    lastName: null,
  },
  schedule: [],
  billing: {
    type: null,
    amount: null,
    parameters: null,
  },
  salary: {
    type: null,
    amount: null,
    parameters: null,
  },

  data: {},
});

// getters
const getters = {
  todaySchedule(state, getters, rootState, rootGetters) {
    /*
     * Moment is used to correctly return today's schedule
     * taking branch's timezone into account.
     * Otherwise, there is unintuitive behaviour when
     * user is located in a different timezone.
     */
    /* const now = new Date();
    let weekday = now.getDay() - 1; */
    const tz = (rootGetters['branches/getById'](state.branchId)).timezone;
    const now = moment.tz(tz);
    let weekday = now.day() - 1;
    if (weekday < 0) weekday = 6;
    return state.schedule?.filter((s) => s.weekDay === weekday) ?? [];
  },
  isToday(state, getters) {
    return !!getters.todaySchedule();
  },
  getBilling(state, getters, rootState, rootGetters) {
    if (state.billing) {
      return state.billing;
    }
    const course = rootGetters['courses/getById'](state.courseId);
    return course.billing;
  },
  getSalary(state, getters, rootState, rootGetters) {
    if (state.salary) {
      return state.salary;
    }
    const course = rootGetters['courses/getById'](state.courseId);
    return course.salary;
  },
};

// actions
const actions = {

  fetchGroup({ commit }, { groupId, signal }) {
    return $api.group._(groupId).get({ fields: ['start_date', 'end_date', 'schedule'] }, { signal })
      .then((response) => {
        commit('setGroup', response);
        return response;
      });
  },
  fetchAll({ dispatch }, { groupId, signal }) {
    return Promise.all([
      dispatch('fetchGroup', { groupId, signal }),
      dispatch('students/fetchStudents', { groupId, signal }),
      dispatch('attendance/fetchAttendance', { groupId, reload: true, signal }),
    ]);
  },

  createGroup({ commit }, {
    branchId,
    data: {
      name, courseId, teacherId, startDate, endDate, schedule, billing, salary, comment,
    },
    updateState = true,
  } = {}) {
    return $api.branch._(branchId).groups.post({
      name,
      course_id: courseId,
      teacher_id: teacherId,
      start_date: startDate,
      end_date: endDate,
      billing,
      salary,
      comment,
      schedule: schedule?.map((s) => ({
        classroom_id: s.classroomId,
        week_day: s.weekDay,
        start_time: s.startTime,
        duration: s.duration,
      })) ?? undefined,
    }).then((response) => {
      if (updateState) {
        commit('setGroup', response);
      }
      return response;
    });
  },
  updateGroup({ state, commit }, {
    groupId = null,
    data: {
      name, courseId, teacherId, startDate, endDate, schedule, billing, salary, comment,
    },
    updateState = true,
  } = {}) {
    const id = groupId ?? state.id;
    return $api.group._(id).patch({
      name,
      course_id: courseId,
      teacher_id: teacherId,
      start_date: startDate,
      end_date: endDate,
      billing,
      salary,
      comment,
      schedule: schedule?.map((s) => ({
        classroom_id: s.classroomId,
        week_day: s.weekDay,
        start_time: s.startTime,
        duration: s.duration,
      })) ?? undefined,
    }).then((response) => {
      if (updateState) {
        commit('setGroup', response);
      }
      return response;
    });
  },
  deleteGroup({ state }, groupId) {
    const id = groupId ?? state.id;
    return $api.group._(id).delete();
  },

};

// mutations
const mutations = {
  setGroup(state, group) {
    state.id = group.id;
    state.branchId = group.branch_id;
    state.courseId = group.course_id;
    state.name = group.name;
    state.comment = group.comment;
    state.startDate = group.start_date;
    state.endDate = group.end_date;
    state.teacher = {
      id: group.teacher.id,
      firstName: group.teacher.first_name,
      lastName: group.teacher.last_name,
      tel: group.teacher.tel,
      email: group.teacher.email,
    };
    state.schedule = group.schedule.map((s) => ({
      classroomId: s.classroom_id,
      weekDay: s.week_day,
      startTime: s.start_time,
      duration: s.duration,
    }));
    state.billing = group.billing;
    state.salary = group.salary;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

  modules: {
    attendance,
    students,
  },
};
