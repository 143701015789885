/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  notifications: [],
});

// getters
const getters = {};

// actions
const actions = {
  fetchNotifications({ commit, rootState }, organizationId) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.notifications.get()
      .then((response) => {
        commit('setNotifications', response);
        return response;
      });
  },

  createNotification(
    { commit, state, rootState },
    {
      organizationId,
      data: {
        enabled, type, role, telCustomField, sms, emailCustomField, email, sendTime, optional, defaultState,
      },
    },
  ) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.notifications.post({
      enabled,
      type,
      role,
      sms,
      email,
      optional,
      default: defaultState,
      tel_custom_field: telCustomField,
      email_custom_field: emailCustomField,
      send_time: sendTime,
    })
      .then((createdNotification) => {
        commit(
          'setNotifications',
          [...state.notifications, createdNotification],
        );
        return createdNotification;
      });
  },

  updateNotification(
    { commit, state, rootState },
    {
      organizationId,
      notificationId,
      data: {
        enabled, type, role, telCustomField, sms, emailCustomField, email, sendTime, optional, defaultState,
      },
    },
  ) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.notifications._(notificationId).patch({
      enabled,
      type,
      role,
      sms,
      email,
      optional,
      default: defaultState,
      tel_custom_field: telCustomField,
      email_custom_field: emailCustomField,
      send_time: sendTime,
    })
      .then((updatedNotification) => {
        commit(
          'setNotifications',
          state.notifications.map((n) => (n.id === notificationId ? updatedNotification : n)),
        );
        return updatedNotification;
      });
  },

  deleteNotification({ commit, state, rootState }, {
    organizationId,
    notificationId,
  }) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.notifications._(notificationId).delete()
      .then(() => {
        commit(
          'setNotifications',
          state.notifications.filter((n) => n.id !== notificationId),
        );
      });
  },
};

// mutations
const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications.map(
      ({
        tel_custom_field: telCustomField,
        email_custom_field: emailCustomField,
        send_time: sendTime,
        default: defaultState,
        ...data
      }) => ({
        telCustomField,
        emailCustomField,
        sendTime,
        defaultState,
        ...data,
      }),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
