/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  leadStatuses: [],
  leadCustomFields: [],
});

// getters
const getters = {};

// actions
const actions = {
  fetchAll({ dispatch }, organizationId) {
    return Promise.all([
      dispatch('fetchLeadStatuses', organizationId),
      dispatch('fetchLeadCustomFields', organizationId),
    ]);
  },

  fetchLeadStatuses({ commit, rootState }, organizationId) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.leads.statuses.get()
      .then((response) => {
        commit('setLeadStatuses', response);
        return response;
      });
  },

  fetchLeadCustomFields({ commit, rootState }, organizationId) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.leads.fields.get()
      .then((response) => {
        commit('setLeadCustomFields', response);
        return response;
      });
  },

  createLeadStatus(
    { commit, state, rootState },
    {
      organizationId,
      data: {
        name, autoclose, color,
      },
    },
  ) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.leads.statuses.post({
      name,
      autoclose,
      color,
    })
      .then((createdStatus) => {
        commit(
          'setLeadStatuses',
          [...state.leadStatuses, createdStatus],
        );
        return createdStatus;
      });
  },

  updateLeadStatus(
    { commit, state, rootState },
    {
      organizationId,
      leadStatusId,
      data: {
        name, autoclose, color,
      },
    },
  ) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.leads.statuses._(leadStatusId).patch({
      name,
      autoclose,
      color,
    })
      .then((updatedStatus) => {
        commit(
          'setLeadStatuses',
          state.leadStatuses.map((s) => (s.id === leadStatusId ? updatedStatus : s)),
        );
        return updatedStatus;
      });
  },

  deleteLeadStatus({ commit, state, rootState }, {
    organizationId,
    leadStatusId,
  }) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.leads.statuses._(leadStatusId).delete()
      .then(() => {
        commit(
          'setLeadStatuses',
          state.leadStatuses.filter((s) => s.id !== leadStatusId),
        );
      });
  },

  createLeadCustomField(
    { commit, state, rootState },
    {
      organizationId,
      data: {
        name, showInList,
      },
    },
  ) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.leads.fields.post({
      name,
      show_in_list: showInList,
    })
      .then((createdField) => {
        commit(
          'setLeadCustomFields',
          [...state.leadCustomFields, createdField],
        );
        return createdField;
      });
  },

  updateLeadCustomField(
    { commit, state, rootState },
    {
      organizationId,
      leadCustomFieldId,
      data: {
        name, showInList, order,
      },
    },
  ) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.leads.fields._(leadCustomFieldId).patch({
      name,
      show_in_list: showInList,
      order,
    })
      .then((updatedField) => {
        commit(
          'setLeadCustomFields',
          state.leadCustomFields.map((f) => (f.id === leadCustomFieldId ? updatedField : f)),
        );
        return updatedField;
      });
  },

  deleteLeadCustomField({ commit, state, rootState }, {
    organizationId,
    leadCustomFieldId,
  }) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).settings.leads.fields._(leadCustomFieldId).delete()
      .then(() => {
        commit(
          'setLeadCustomFields',
          state.leadCustomFields.filter((f) => f.id !== leadCustomFieldId),
        );
      });
  },

  reorderLeadCustomFields({ state, dispatch }, { fromIndex, toIndex }) {
    const movedUp = toIndex < fromIndex;
    const movedDown = !movedUp;
    const changedFields = [];
    state.leadCustomFields.forEach((field, index) => {
      if (index === fromIndex) {
        // change
        changedFields.push({
          leadCustomFieldId: field.id,
          data: {
            order: toIndex,
          },
        });
      } else if (movedUp && index >= toIndex) {
        // inc
        changedFields.push({
          leadCustomFieldId: field.id,
          data: {
            order: index + 1,
          },
        });
      } else if (movedDown && index <= toIndex) {
        /// dec
        changedFields.push({
          leadCustomFieldId: field.id,
          data: {
            order: index - 1,
          },
        });
      }
    });
    return Promise.all(
      changedFields.map((field) => dispatch('updateLeadCustomField', field)),
    );
  },

};

// mutations
const mutations = {
  setLeadStatuses(state, statuses) {
    state.leadStatuses = statuses;
  },
  setLeadCustomFields(state, fields) {
    state.leadCustomFields = fields.map(
      ({
        // eslint-disable-next-line camelcase
        id, name, order, show_in_list,
      }) => ({
        id,
        name,
        order,
        // eslint-disable-next-line camelcase
        showInList: show_in_list,
      }),
    ).sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
