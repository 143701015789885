<template>
  <div
    class="overflow-hidden text-xs w-full flex-1"
    :class="{
      rounded: rounded,
      'bg-primary-200': color == 'primary',
      'bg-success-200': color == 'success',
      'bg-info-200': color == 'info',
      'bg-warning-200': color == 'warning',
      'bg-error-200': color == 'error',
    }"
    :style="{ height: height + 'px' }"
  >
    <div
      :style="`width:${value}%`"
      :class="{
        'bg-primary-500': color == 'primary',
        'bg-success-500': color == 'success',
        'bg-info-500': color == 'info',
        'bg-warning-500': color == 'warning',
        'bg-error-500': color == 'error',
        'progress-infinite': infinite,
      }"
      class="h-full shadow-none relative text-center whitespace-nowrap text-white justify-center"

      role="progressbar"
      :aria-valuenow="value"
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    color: {
      type: String,
      default: 'primary',
    },
    height: {
      type: [Number, String],
      default: 5,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    infinite: Boolean,
  },
  data() {
    return {

    };
  },
};
</script>

<style lang="scss">
  .progress-infinite {
    animation-name: progress-infinite;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes progress-infinite {
    0% { width: 0; }
    50% { width: 100%; left: 0; }
    100% { width: 0; left: 100%; }
  }
</style>
