/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  settings: {},
});

// getters
const getters = {

};

// actions
const actions = {

  fetchSettings({ commit, rootState }, { memberId, signal } = {}) {
    const id = memberId ?? rootState.member.id;
    return $api.member._(id).notifications.get(null, { signal })
      .then((response) => {
        commit('setSettings', response);
        return response;
      });
  },

  updateSettings({ commit, rootState }, { memberId, data }) {
    const id = memberId ?? rootState.member.id;
    return $api.member._(id).notifications.patch(data)
      .then((response) => {
        commit('setSettings', response);
        return response;
      });
  },

};

// mutations
const mutations = {
  setSettings(state, settings) {
    state.settings = settings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
