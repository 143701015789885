/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  // TODO: replace the object with inner properties right in the state
  account: {},
  stats: [],
});

// getters
const getters = {
  // eslint-disable-next-line eqeqeq
  getSubAccountById: (state) => (id) => state.account.subAccounts.find((sa) => sa.id == id),
};

// actions
const actions = {

  fetchAccount({ commit, rootState }, { memberId, signal } = {}) {
    const id = memberId ?? rootState.member.id;
    return $api.member._(id).account.get(null, { signal })
      .then((response) => {
        commit('setAccount', response);
        return response;
      });
  },

  fetchAccountStats({ commit, rootState }, { memberId, signal } = {}) {
    const id = memberId ?? rootState.member.id;
    return $api.member._(id).account.stats.get(null, { signal })
      .then((response) => {
        commit('setAccountStats', response);
        return response;
      });
  },

  createTransaction({ dispatch, rootState }, {
    memberId = null, datetime, subAccountId, amount, description, isReal, updateState = true,
  } = {}) {
    const id = memberId ?? rootState.member.id;
    return $api.member._(id).transactions.post({
      datetime,
      sub_account_id: subAccountId || null, // too long to explain
      amount,
      description,
      is_real: isReal,
    }).then((response) => {
      if (updateState) {
        dispatch('fetchAccount');
      }
      return response;
    });
  },

  deleteTransaction({ dispatch, rootState }, { memberId = null, transactionId, updateState = true }) {
    const id = memberId ?? rootState.member.id;
    return $api.member._(id).transactions._(transactionId).delete()
      .then((response) => {
        if (updateState) {
          dispatch('fetchAccount');
        }
        return response;
      });
  },

};

// mutations
const mutations = {
  setAccount(state, account) {
    state.account = {
      balance: account.balance,
      subAccounts: account.sub_accounts,
    };
  },
  setAccountStats(state, accountStats) {
    state.stats = accountStats;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
