export default {
  // called when bound element's parent component is mounted
  mounted(el: HTMLBaseElement, { arg, modifiers, value }: any) {
    let placeholder: HTMLElement;
    let observerStatic: IntersectionObserver;
    let observerFixed: IntersectionObserver;

    const options = {
      root: document,
      rootMargin: '0px',
      threshold: 0.01,
    };

    const callbackStatic = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      /* eslint-disable no-param-reassign */
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.intersectionRatio === 0) {
          const style = window.getComputedStyle(el, null);

          placeholder = document.createElement('div');
          placeholder.style.display = style.display;
          placeholder.style.width = `${el.offsetWidth}px`;
          placeholder.style.height = `${el.offsetHeight}px`;
          placeholder.innerHTML = '&nbsp;';
          el.parentNode!.insertBefore(placeholder, el);

          el.style.width = `${el.offsetWidth}px`;
          el.style.position = 'fixed';
          el.style.top = '0px';

          observer.unobserve(el);
          observerFixed.observe(placeholder);
        }
      });
    };

    const callbackFixed = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      /* eslint-disable no-param-reassign */
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.intersectionRatio > 0) {
          observer.unobserve(placeholder);
          placeholder.remove();

          el.style.width = '';
          el.style.position = '';
          el.style.top = '';

          observerStatic.observe(el);
        }
      });
    };

    observerStatic = new IntersectionObserver(callbackStatic, options);
    observerFixed = new IntersectionObserver(callbackFixed, options);

    observerStatic.observe(el);
  },

};
