/* eslint-disable no-shadow */
import ls from 'local-storage';
import $api from '@/api/singleton';

const state = () => ({
  isAuthenticated: false,
  token: null,
  userId: null,
});

// getters
const getters = {};

// actions
const actions = {
  loadInitialData({ dispatch /* commit */ }) {
    // commit('ui/setLoading', true, { root: true });
    return Promise.all([
      dispatch('user/fetchUser', null, { root: true }),
    ]).finally(() => {
      // commit('ui/setLoading', false, { root: true });
    });
  },
  logIn({ commit, dispatch }, { username, password }) {
    return $api.user.token.post({
      username: username.replace(/[^+0-9]/g, ''), // delete everything but numbers
      password,
    })
      .then((data) => {
        commit('setCredentials', {
          token: data.token,
          userId: data.user_id,
        });
        commit('setAuthenticated', true);

        ls('auth', {
          token: data.token,
          expires: Date.now() + (data.expires_in * 1000),
          userId: data.user_id,
        });

        window.dataLayer.push({ event: 'user_login' });

        return dispatch('loadInitialData');
      });
  },
  logInWithSavedCredentials({ commit, dispatch }) {
    const auth = ls('auth');
    if (auth /* && auth.expires > Date.now() */) {
      commit('setCredentials', {
        token: auth.token,
        userId: auth.user_id,
      });
      commit('setAuthenticated', true);

      return dispatch('loadInitialData');
    }
    return false;
  },
  logOut({ commit }) {
    ls.remove('auth');
    commit('setAuthenticated', false);
    commit('setCredentials');
  },
};

// mutations
const mutations = {
  setAuthenticated(state, value) {
    state.isAuthenticated = value;
  },
  setCredentials(state, { token = null, userId = null } = {}) {
    state.token = token;
    state.userId = userId;
    if (token) {
      $api.setHeaders({
        Authorization: `token ${token}`,
      });
    } else {
      $api.setHeaders(null);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
