/* eslint-disable no-shadow */
import $api from '@/api/singleton';

import attendance from './attendanceStatuses';
import fields from './fields';
import notifications from './notifications';
import leads from './leads';

const state = () => ({
  general: {
    defaultTimezone: 'UTC',
    defaultClassDuration: 45,
  },
});

// getters
const getters = {
};

// actions
const actions = {
  fetchAll({ dispatch }) {
    return Promise.all([
      dispatch('fetchGeneralSettings'),
      dispatch('fields/fetchCustomFields'),
      dispatch('notifications/fetchNotifications'),
      dispatch('attendance/fetchAttendanceStatuses'),
      dispatch('leads/fetchAll'),
    ]);
  },

  updateGeneralSettings(
    { commit, rootState },
    {
      organizationId,
      data: {
        defaultTimezone, defaultClassDuration,
      },
    },
  ) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).settings.patch({
      default_timezone: defaultTimezone,
      default_class_duration: defaultClassDuration,
    })
      .then((updatedSettings) => {
        if (organizationId === rootState.organizations.currentId) {
          commit('setGeneralSettings', updatedSettings);
        }
        return updatedSettings;
      });
  },

  fetchGeneralSettings({ commit, rootState }, organizationId) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).settings.get()
      .then((settings) => {
        commit('setGeneralSettings', settings);
        return settings;
      });
  },
};

// mutations
const mutations = {
  setGeneralSettings(state, settings) {
    ({
      default_timezone: state.general.defaultTimezone,
      default_class_duration: state.general.defaultClassDuration,
    } = settings);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

  modules: {
    fields,
    attendance,
    notifications,
    leads,
  },
};
