/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({
  settings: {
    provider: null,
    providerParams: {},
  },
  templates: [],
});

// getters
const getters = {};

// actions
const actions = {
  fetchSettings({ commit, rootState }, organizationId) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).sms.settings.get()
      .then((response) => {
        commit('setSettings', response);
        return response;
      });
  },
  updateSettings(
    { commit, rootState },
    // eslint-disable-next-line camelcase
    { organizationId, data: { provider, providerParams: provider_params } },
  ) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).sms.settings.patch({
      provider,
      // eslint-disable-next-line camelcase
      provider_params,
    })
      .then((response) => {
        commit('setSettings', response);
        return response;
      });
  },

  fetchTemplates({ commit, rootState }, organizationId) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).sms.templates.get()
      .then((response) => {
        commit('setTemplates', response);
        return response;
      });
  },
  createTemplate({ commit, state, rootState }, { organizationId, data: { name, text } }) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).sms.templates.post({
      name,
      text,
    })
      .then((createdTemplate) => {
        commit(
          'setTemplates',
          [...state.templates, createdTemplate],
        );
        return createdTemplate;
      });
  },
  updateTemplate({ commit, state, rootState }, { organizationId, templateId, data: { name, text } }) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).sms.templates._(templateId).patch({
      name,
      text,
    })
      .then((updatedTemplate) => {
        commit(
          'setTemplates',
          state.templates.map((t) => (t.id === templateId ? updatedTemplate : t)),
        );
        return updatedTemplate;
      });
  },
  deleteTemplate({ commit, state, rootState }, {
    organizationId,
    templateId,
  }) {
    const orgId = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(orgId).sms.templates._(templateId).delete()
      .then(() => {
        commit(
          'setTemplates',
          state.templates.filter((t) => t.id !== templateId),
        );
      });
  },

  sendSMS({ rootState }, { organizationId, data: { recipientName, recipients, text } }) {
    const id = organizationId ?? rootState.organizations.currentId;
    return $api.organization._(id).sms.post({
      recipient_name: recipientName,
      recipients: recipients.join(','),
      text,
    });
  },
};

// mutations
const mutations = {
  setSettings(state, settings) {
    state.settings = {
      provider: settings.provider,
      providerParams: settings.provider_params,
    };
  },
  setTemplates(state, templates) {
    state.templates = templates;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
