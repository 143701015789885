import axios from 'axios';
import Endpoint from './endpoint';

const ApiClientProxy = {
  get(target, prop, receiver) {
    if (prop in target) {
      return Reflect.get(target, prop, receiver);
    }
    return new Endpoint(target, [prop]);
  },
};

class ApiClient {
  constructor(baseUrl, { trailingSlash = true } = {}) {
    this.trailingSlash = trailingSlash;
    this.client = axios.create({
      baseURL: baseUrl,
    });
    // eslint-disable-next-line no-constructor-return
    return new Proxy(this, ApiClientProxy);
  }

  interceptRequest(beforeSend, onError = null) {
    this.client.interceptors.request.use(beforeSend, onError);
  }

  interceptResponse(onSuccess, onError = null) {
    this.client.interceptors.response.use(onSuccess, onError);
  }

  setHeaders(headers) {
    this.client.defaults.headers.common = headers;
  }

  _trailingSlash(url) {
    return url.replace(/\/$/g, '') + (this.trailingSlash ? '/' : '');
  }

  get(endpoint, params = null, config = null) {
    return this.client.get(this._trailingSlash(endpoint), {
      ...config,
      params,
    }).then((response) => response.data);
  }

  post(endpoint, data, params = null) {
    return this.client.post(this._trailingSlash(endpoint), data, {
      params,
    }).then((response) => response.data);
  }

  patch(endpoint, data, params = null) {
    return this.client.patch(this._trailingSlash(endpoint), data, {
      params,
    }).then((response) => response.data);
  }

  delete(endpoint, params = null) {
    return this.client.delete(this._trailingSlash(endpoint), {
      params,
    }).then((response) => response.data);
  }
}

export default ApiClient;
