<template>
  <ui-progress v-if="isLoadingView" infinite :rounded="false" :height="3" class="fixed inset-x-0 top-0 z-30" />
  <loading-overlay v-model="isLoading">
    <router-view />
  </loading-overlay>
  <notification-queue />
</template>

<script>
import { mapState } from 'vuex';

import loadingOverlay from '@/components/loadingOverlay.vue';
import notificationQueue from '@/components/notificationQueue.vue';
import UiProgress from './components/ui/uiProgress.vue';

export default {
  name: 'App',
  components: {
    UiProgress,
    loadingOverlay,
    notificationQueue,
  },
  computed: {
    ...mapState('ui', ['isLoading', 'isLoadingView', 'title']),
  },
  watch: {
    title() {
      // change window title
      document.title = this.title;
    },
  },
};
</script>
