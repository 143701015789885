/* eslint-disable no-shadow */
import $api from '@/api/singleton';

const state = () => ({});

// getters
const getters = {};

// actions
const actions = {
  fetchAdmins(_, { branchId, params }) {
    return $api.branch._(branchId).admins.get(params)
      .then((teachers) => teachers);
  },

  addAdmin(_, { branchId, memberId }) {
    return $api.branch._(branchId).admins.post({
      member_id: memberId,
    });
  },

  removeAdmin(_, { branchId, memberId }) {
    return $api.branch._(branchId).admins._(memberId).delete();
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
